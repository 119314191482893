import React, { useState, useEffect } from 'react';
import { useAuthenticator, useTheme, ThemeProvider } from '@aws-amplify/ui-react';
import { Authenticator, CheckboxField} from '@aws-amplify/ui-react';
import Modal from 'react-bootstrap/Modal';
import { Button, Heading, Image, Text, View } from '@aws-amplify/ui-react';
import {Row, Col, Form } from "reactstrap";
import './index.css';
import { Hub } from "aws-amplify/utils";
import { signInWithRedirect, signOut, getCurrentUser, AuthUser } from "aws-amplify/auth";

// const digiLabIconURI = Image.resolveAssetSource(digiLabIcon).uri;

export const components = {
    Header() {
      const { tokens } = useTheme();
  
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Row>
                    <Col className="text-center">
                        <h1 className='twinlab' onClick={() => window.location.href = '/'}>twinLab</h1>
                    </Col>
                </Row>
        </View>
      );
    },
  
    Footer() {
      const { tokens } = useTheme();
  
      return (
        // The padding here is a temporal fix to the white space at the bottom
        <View textAlign="center" padding={15}> 
          <Text color={tokens.colors.neutral[80]}>
            &copy; All Rights Reserved
          </Text>
        </View>
      );
    },
  
    SignIn: {
      Header() {
        const { tokens } = useTheme();
  
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Sign In to Your Account
          </Heading>
        );
      },
      Footer() {
        const { toForgotPassword } = useAuthenticator();

        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toForgotPassword}
              size="small"
              variation="link"
            >
              Reset Password
            </Button>
          </View>
        );
      },
    },
  
    SignUp: {
      Header() {
        const { tokens } = useTheme();
  
        return (
          <div>
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
          
            Create a New Account
          </Heading>
          <Text style={{ marginTop: '20px' }}> Your password must be at least <strong>eight characters</strong> long and include (i) <strong>one uppercase letter</strong>, (ii) <strong>one number</strong>, and (iii) <strong>one special character/symbol</strong>.
          </Text>
          {/* Terms and conditions */}
          </div>
        );
      },
      FormFields() {
        const { validationErrors } = useAuthenticator();
        return (
          <>
                {/* Re-use default `Authenticator.SignUp.FormFields` */}
                <Authenticator.SignUp.FormFields />

                {/* Append & require Terms and Conditions field to sign up  */}
                <CheckboxField
                  errorMessage={validationErrors.acknowledgement as string}
                  hasError={!!validationErrors.acknowledgement}
                  name="acknowledgement"
                  value="yes"
                  label={
                    <span>
                      I agree with the{' '}
                      {/* Change twinla.ai for the actual TC page */}
                      <a href="https://www.digilab.co.uk/termsofservice" target="_blank" rel="noopener noreferrer">
                        Terms and Conditions
                      </a>
                    </span>
                  }
                > 
                {/* I agree with the <link href="#"> Terms & Conditions </link> */}
                </CheckboxField>
              </>)
              },
      Footer() {
        const { toSignIn } = useAuthenticator();
        const { validationErrors } = useAuthenticator(); 
  
        return (
          
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toSignIn}
              size="small"
              variation="link"
            >
              Back to Sign In
            </Button>
          </View>
        );
      },
    },
    ConfirmSignUp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information
          </Heading>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();
  
        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toSignIn}
              size="small"
              variation="link"
            >
              Back to Sign In
            </Button>
          </View>
        );
      },
    },
    SetupTotp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    ForgotPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <div style={{ padding: `${tokens.space.lg}` }}>
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information
          </Heading>
          <Text style={{ marginTop: '20px'}}> If this email is associated with a registered account on the twinLab Portal, a confirmation code will be sent that will allow you to reset your password. The arrival of this email may take up to a minute. Please be sure to also check your spam messages when looking for the confirmation code. </Text>
          </div>
        );
      },
      // Footer() {
      //   return <Text>Footer Information</Text>;
      // },
    },
    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <div>
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information
          </Heading>
          <Text style={{ marginTop: '20px' }}> If your account is registered you will have received a confirmation code. Please enter it and choose a new password.</Text>
          </div>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();
  
        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toSignIn}
              size="small"
              variation="link"
            >
              Back to Sign In
            </Button>
          </View>
        );
      },
    },
  };
  
export const formFields = {
    signIn: {
      username: {
        label: 'Email',
        placeholder: 'Enter Your Email',
      },
      password: {
        label: 'Password',
        placeholder: 'Enter Your Password',
      },
    },
    signUp: {
     email: {
        label: 'Email',
        placeholder: 'Enter Your Email',
        order: 1,
    },
     password: {
        label: 'Password',
        placeholder: 'Enter Your Password',
        isRequired: false,
        order: 2,
      },
      confirm_password: {
        label: 'Confirm Your Password',
        placeholder: 'Enter Your Password Again',
        order: 3,
      },
    },
    forceNewPassword: {
      password: {
        placeholder: 'Enter Your Password:',
      },
    },
    forgotPassword: {
      username: {
        label: 'Email',
        placeholder: 'Enter Your Email',
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        placeholder: 'Enter Your Confirmation Code',
        label: 'Confirmation Code',
        isRequired: false,
      },
      confirm_password: {
        placeholder: 'Enter Your Password',
      },
    },
    setupTotp: {
      QR: {
        totpIssuer: 'test issuer',
        totpUsername: 'amplify_qr_test_user',
      },
      confirmation_code: {
        label: 'Confirmation Code',
        placeholder: 'Enter Your Confirmation Code:',
        isRequired: false,
      },
    },
    confirmSignIn: {
      confirmation_code: {
        label: 'Confirmation Code',
        placeholder: 'Enter Your Confirmation Code:',
        isRequired: false,
      },
    },
  };

