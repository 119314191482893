
// INTERFACES

export interface UserInfo {
    name: string;
    api_key: string;
    sign: boolean;
    sign_date: string;
  }

// CONSTANTS

export const initialUser: UserInfo = {
    name: 'John Doe',
    api_key: 'default_key',
    sign: false,
    sign_date: '01/01/2024',
  };

// FUNCTIONS

export async function checkUser(inputName: string| undefined, token: string| undefined): Promise<UserInfo | null> {
  
  try {
      const response = await fetch(`/api/user/${inputName}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error checking user:', error);
      throw error;
    }
  }

