import '../index.css';
import { Container, Row, Col, Button} from "reactstrap";
interface ContactPageProps {
    signOut: () => void;
}

function ContactPage(props: ContactPageProps) {

    return (
    <div className='homebox'>
        <Container className='center-container'>
            <h1 className='welcomeheader'>Welcome to twinLab</h1>
            <h2 className='welcomeheader2'>
            You are not yet registered in the twinLab cloud.</h2>
            <h3 className='welcomeheader2'> If you believe you have received this message in error, or you would like to set-up a trial, please contact us via email at twinlab@digilab.co.uk.</h3>
            <p className='link'>Visit <a href="https://twinlab.ai" className="link">twinlab.ai</a> for more information.</p>
            
            <div>
                <Button className='buttonuser' onClick={() => props.signOut()}> Sign Out </Button>
            </div>
        </Container>
    </div>
    );

} export default ContactPage;