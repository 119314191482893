import React from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator, useAuthenticator} from '@aws-amplify/ui-react';
import { fetchAuthSession } from '@aws-amplify/auth';
import '@aws-amplify/ui-react/styles.css';
import Home from './pages/home';
import { components, formFields } from './authComponents';
import './App.css';
//
import awsconfig from './aws-exports';

Amplify.configure(awsconfig); //Configuration coming from aws-exports.js. It still works with the old configuration but includes Google social provider. If want to use the old configuration, just comment this line and uncomment the old configuration below.

// This is the old configuration, it still works but it doesn't include Google social provider.
// Amplify.configure({
//   Auth: {
//     Cognito: {
//       userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID || '',
//       userPoolId: process.env.REACT_APP_USER_POOL_ID || '',
//       loginWith: { 
//         oauth: {
//           domain: process.env.REACT_APP_COGNITO_DOMAIN || '',
//           responseType: 'code',
//           redirectSignIn: ['http://localhost:3000/'],
//           redirectSignOut: ['http://localhost:3000/'],
//           scopes: ['email']
//         },
//         username: true,
//       }
//     }
//   }
// });



function App() {

  // This is how to check the redirectSignIn from the window and from the configuration. Useful when the redirectSignIn is not working as expected.
  // const signInRedirectFromWindow = String(window.location.origin + window.location.pathname)
  // const signInRedirectFromConfig = awsconfig.oauth.redirectSignIn
  
  // console.log("This is the window", { signInRedirectFromWindow })
  // console.log("This is from config", { signInRedirectFromConfig })

  
  return (
    <Authenticator className='App' formFields={formFields} components={components} services={{
      async validateCustomSignUp(formData) {
        if (!formData.acknowledgement) {
          return {
            acknowledgement: 'You must agree to the Terms and Conditions.',
          };
        }
      },
    }}>
      {/* The Authenticator component call this function with an object containing signOut and User (both coming from the library). We pass the state of those two elements to Home to render one page or another*/}
      {({ signOut, user }) => {
    return (
    <Home user={user} signOut={signOut} />
  );
}}
    </Authenticator>
  )
}

export default App;