import React from 'react';
import { useState } from 'react';
import { Container, Row, Col, Button} from "reactstrap";
import '../index.css';
import { UserInfo, initialUser} from '../utils';

import { initializeIcons } from '@fluentui/react/lib/Icons';
import { Icon, IconButton } from '@fluentui/react';
import Auth from '@aws-amplify/auth';

initializeIcons();

interface UserPageProps {
  data: UserInfo ;
  setData: React.Dispatch<React.SetStateAction<UserInfo | null>>;
  signOut: () => void;
  token: string | undefined;
}

function MainPage(props: UserPageProps) {

  const [generatedKey, setGeneratedKey] = useState<boolean>(false); // This is the key that will be generated and shown to the user

  const changeApiKey = async (data: UserInfo) => {
    try {
      let name = data.name;
      let token = props.token;
      const response = await fetch(`/api/update_api_key/${name}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        // body: JSON.stringify({ name }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const new_data = await response.json();

      props.setData({ ...props.data, api_key: new_data.api_key });
      setGeneratedKey(true);

    } catch (error) {
      console.error('Error updating API key:', error);
    }
  };

  const openExternalLink = () => {
    window.open("https://twinlab.ai/python/quickstart/index.html", "_blank", "noopener,noreferrer");
  }; // _blank opens in a new tab and noopener,noreferrer is a security measure
return (
  <div className='homebox'>
    <Container className='center-container'>
      <h1 className="welcomeheader">Welcome to twinLab</h1>
        <div>
        <Button className='buttonuser' onClick={() => changeApiKey(props.data)}>
              Generate new API key
            </Button>

        {/* Conditionally render the info box */}
      {generatedKey && (
        <div className='box-api-key'>
          <h3> This is your API key: </h3>
          <div className='apikey'>
          {props.data.api_key} <IconButton className='iconcoppy' iconProps={{ iconName: 'copy' }} styles = {{root: { color: '#162448' }, rootHovered: { transform: 'scale(1.2)' } }}onClick={() => navigator.clipboard.writeText(props.data?.api_key ?? '')}/>
          </div>
          <p>For security reasons, this API key will only appear once. Ensure you copy this API key in a secure place. In case you loose track of your API key, please generate a new one here.</p>
        </div>
      )}
    </div> 
          <div className='buttondiv'>
            <Button 
            className='buttonuser' 
            onClick={openExternalLink}
          >
            Get started with twinLab
          </Button>
            <Button className='buttonuser' onClick={() => props.signOut()}>
              Sign out
            </Button>
          </div>
    </Container>
  </div>
)

} export default MainPage;


