import React from 'react';

import { checkUser, UserInfo, initialUser } from '../utils';
import MainPage  from './user';
import ContactPage from './contact';
import '../index.css';
import ClipLoader from "react-spinners/ClipLoader";
import { AuthUser } from 'aws-amplify/auth';
import { fetchAuthSession } from '@aws-amplify/auth';

interface HomeProps {
  signOut: any;
  user: AuthUser | undefined;
}

function App({ user, signOut }: HomeProps) {  
  const [data, setData] = React.useState<UserInfo | null>(initialUser);
  const [token, setToken] = React.useState<string | undefined>("");
  const [email, setEmail] = React.useState<string | undefined>("");

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the user session to get the token and email
        const session = await fetchAuthSession();
        const accessToken = session.tokens?.accessToken?.toString(); //Coming from Access Token
        const idTokenEmail = session.tokens?.idToken?.payload.email?.toString(); //Coming from ID Token

        // Set token and email
        setToken(accessToken);
        setEmail(idTokenEmail);

        // Fetch user information
        const userData = await checkUser(idTokenEmail, accessToken);
        setData(userData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this runs once when the component mounts

  if (data === null) {

  // If the database returns null, the user is not registered and they are directed to the contact page.

    return (
      <div className="App">
        <ContactPage signOut={signOut} />
      </div>
    );
  } else if (data === initialUser) {

  // If data is still in its inital state then it means the data is still loading and the user is shown a loading screen.

    return (
      <div className="homebox2">
        <ClipLoader className="loader" color={"#162448"} loading={true} size={50} />
      </div>
    );
  } else {

  // If the user is registered, they are directed to the main page (where they can access and manage their API key).
  
    return (
      <div className="App">
        <MainPage data={data} setData={setData} signOut={signOut} token={token} />
      </div>
    );
  }
}

export default App;
